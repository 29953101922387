import { Environment } from 'prosumer-app/app.model';
import { API_CONFIG, ENV_CONFIG } from 'prosumer-app/libs/eyes-core';

import { Provider } from '@angular/core';

import { endpoints, version } from './environment-commons';

export const environment: Environment = {
  name: 'staging',
  production: true,
  cognito: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_ZV8WDfaLZ',
      userPoolWebClientId: '4lm954gq1ihpv34smd9d8m4f8g',
      userPoolWebClientSecret: '',
      oauth: {
        domain: 'prosumer-staging.auth.eu-west-1.amazoncognito.com',
        scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'https://staging.prosumer.te-ded.com/dashboard',
        redirectSignOut: 'https://staging.prosumer.te-ded.com/home',
        responseType: 'code',
        options: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          AdvancedSecurityDataCollectionFlag: true,
        },
        identityProvider: 'prosumer-staging-idp',
      },
    },
  },
  http: {
    timeoutMs: 300000,
    maxRetryAttempts: 2,
    retryScalingDuration: 1000,
    retryIncludedStatusCodes: [401],
    refreshTokenIncludedStatusCodes: [401],
  },
  api: {
    baseUrl: 'https://api.staging.prosumer.te-ded.com/',
    endpoints,
  },
  version,
  websocket: {
    url: 'wss://wss.staging.prosumer.te-ded.com',
    keepAlive: true,
    keepAliveIntervalMs: 480000,
    retryOnClose: true,
    retryOnError: true,
    retryScalingDuration: 5000,
  },
  sentryDsn:
    'https://164279f2060a4415abb832eb55113689@o258615.ingest.sentry.io/1455717',
  sentryRelease: `prosumer-${version.version}`,
  homeUrl:
    'https://engie.sharepoint.com/sites/TETDataViz/Shared%20Documents/Prosumer%20Website/Staging/index.aspx',
  faqUrl:
    'https://engie.sharepoint.com/sites/TETDataViz/Shared%20Documents/Prosumer%20FAQ/Staging/index.aspx',
  trainingUrl:
    'https://engie.sharepoint.com/sites/TETDataViz/Shared%20Documents/Prosumer%20Video/Staging/index.aspx',
  updatesUrl:
    'https://engie.sharepoint.com/sites/TETDataViz/Shared%20Documents/Prosumer%20Updates/Staging/index.aspx',
  amplitudeAPIKey: 'fc70a09863987a01c5c76f9a7bd8369d',
};

export const provideEnvironmentConfig = (): Provider => {
  return { provide: ENV_CONFIG, useValue: environment };
};

export const provideApiConfig = (): Provider => {
  return { provide: API_CONFIG, useValue: endpoints };
};
